<template>
    <main class="p-5 mt-2">
        <div class="top-header flex justify-between items-center align-items-center">
            <div class="text-xl font-semibold">{{ $t('webshop') }}</div>
            <router-link :to="{ name : 'add-webshop'}" class="bg-theme-yellow px-3 py-2 rounded-md text-white">{{ $t('addWebshop') }}</router-link>
        </div>
        <div class="mt-10 mb-10">
            <div class="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 gap-4">
                <div class="col-span-3 flex flex-col items-center align-items-center space-x-4 border shadow-sm rounded-md shadow-sm overflow-hidden">
                    <div class="flex justify-between w-full bg-light-teal p-2 border-b">
                        <h2 class="text-sm">{{ $t('webshopOverview') }}</h2>
                    </div>
                    <div class="flex w-full">
                        <div class="block w-full overflow-x-auto md:overflow-x-hidden overflow-y-hidden">
                            <table class="table md:table-fixed w-full mb-10" border="4">
                                <thead>
                                    <!-- <tr>
                                        <th class="text-left whitespace-nowrap p-2 md:p-0"><span class="text-xs text-gray-900">Customer Name</span></th>
                                        <th class="text-left whitespace-nowrap p-2 md:p-0"><span class="flex justify-between items-center mt-1 text-xs text-gray-900">Invoice Status <span class="flex flex-col"><span @click="sortInvoiceStatusUp" class="cursor-pointer"><img src="./../../assets/images/arrow-up.png" alt=""></span><span @click="sortInvoiceStatusDown" class="cursor-pointer"><img src="./../../assets/images/arrow-down.png" alt=""></span></span></span></th>
                                        <th class="text-left whitespace-nowrap p-2 md:p-0"><span class="flex justify-between items-center mt-1 text-xs text-gray-900">Invoice Status <span class="flex flex-col"><span @click="sortInvoiceStatusUp" class="cursor-pointer"><img src="./../../assets/images/arrow-up.png" alt=""></span><span @click="sortInvoiceStatusDown" class="cursor-pointer"><img src="./../../assets/images/arrow-down.png" alt=""></span></span></span></th>
                                        <th class="text-left whitespace-nowrap p-2 md:p-0"><span class="text-xs text-gray-900">Action</span></th>
                                    </tr> -->
                                </thead>
                                <tbody>
                                    <tr v-for="webshop in GET_WEBSHOPS.results" :key="webshop.id">
                                        <td>
                                            <span>
                                                <img class="h-28 w-32 object-contain" :src="webshop.company_logo" alt="webshop | jitcontrol" v-if="webshop.company_logo">
                                                <!-- <img class="h-28 w-32 object-contain" src="./../../assets/images/webshop.png" alt="webshop | jitcontrol" v-else> -->
                                            </span>
                                        </td>
                                        <td><span class="whitespace-nowrap p-2 md:p-0 text-sm text-gray-900">{{ webshop.shop_name }}</span></td>
                                        <td>
                                        <router-link :to="{ name : 'update-webshop', params : { id : webshop.uuid }}" class="flex items-center cursor-pointer font-semibold space-x-4 whitespace-nowrap p-2 md:p-0 text-sm text-green-500">
                                            <span class="mr-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                                                </svg>
                                            </span>
                                            {{ $t('update') }}
                                        </router-link></td>
                                        <td><span @click="openDeleteSubscription(webshop)" class="flex items-center cursor-pointer space-x-3 font-semibold whitespace-nowrap p-2 md:p-0 text-sm text-red-500">
                                            <span class="mr-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-red-500" viewBox="0 0 20 20" fill="currentColor">
                                                <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
                                                </svg>
                                            </span>
                                            {{ $t('disconnect') }}
                                            </span>
                                        </td>
                                        <td>
                                            <span class="mb-10">
                                                <router-link :to="{ name : 'webshop-single', params : { uuid : webshop.uuid }}" class="bg-green-500 px-3 py-2 rounded-md text-white cursor-pointer whitespace-nowrap">{{ $t('enter') }} {{ $t('webshop') }}</router-link>
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <span v-if="loading" class="flex justify-center w-full">
                                <svg class="text-center animate-spin mr-2 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-10 mb-20">
            <div class="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 gap-4">
                <div class="col-span-3 flex flex-col items-center align-items-center space-x-4 border shadow-sm rounded-md shadow-sm overflow-hidden">
                    <div class="flex justify-between w-full bg-light-teal p-2 border-b">
                        <h2 class="text-sm">{{ $t('selectEcommerce') }}</h2>
                    </div>
                    <div class="flex w-full">
                        <div class="block w-full overflow-x-auto md:overflow-x-hidden overflow-y-hidden">
                            <div class="align-items-center items-center grid grid-cols-1 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 gap-4">
                                <div class="flex">
                                    <div class="flex flex-col p-20">
                                        <div class="mb-5">
                                            <img src="./../../assets/images/ecommerce/starweb.png" alt="">
                                        </div>
                                        <button class="flex justify-center bg-theme-green px-3 py-2 focus:outline-none rounded-md text-white">{{ $t('connect') }}</button>
                                    </div>
                                </div>
                                <div class="flex">
                                    <div class="flex flex-col p-20">
                                        <div class="mb-5">
                                            <img src="./../../assets/images/ecommerce/shopify.png" alt="">
                                        </div>
                                        <button @click="openShopifyModal = true" class="flex justify-center bg-theme-green px-3 py-2 focus:outline-none rounded-md text-white">
                                            <span>
                                                {{ $t('connect') }}
                                            </span>
                                        </button>
                                    </div>
                                </div>
                                <div class="flex">
                                    <div class="flex flex-col p-20">
                                        <div class="mb-5">
                                            <img src="./../../assets/images/ecommerce/woo.png" alt="">
                                        </div>
                                        <button class="flex justify-center bg-theme-green px-3 py-2 focus:outline-none rounded-md text-white">{{ $t('connect') }}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
    <ModalBox v-if="openShopifyModal" :title="$t('configureShopify')" width="md:w-1/4 w-full" @handleClose='openShopifyModal = false'>
        <div class="flex flex-col w-full overflow-x-hidden">
            <form class="flex flex-col w-full" @submit.prevent="initializingShopify">
                <div class="flex flex-col p-2">
                    <span class="text-sm text-gray-600 w-full mb-2">{{ $t('shopName') }} <span class="text-red-500">*</span></span>
                    <div class="form-group w-full pr-3 md:pr-0 mb-2">
                        <input v-model="shopName" type="text" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                    </div>
                </div>
                <div>

                </div>
                <button type="submit" :disabled="loadingShopify" class="flex justify-center bg-theme-green px-3 py-2 focus:outline-none rounded-md text-white">
                    <span v-if="loadingShopify" class="flex space-x-2">
                        <svg class="animate-spin mr-2 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        {{ $t('connecting') }}
                    </span>
                    <span v-else>
                        {{ $t('save') }}
                    </span>
                </button>
            </form>
        </div>
    </ModalBox>

    <ModalBox v-if="openEditBox" :title="$t('updateWebshop')" width="md:w-3/4 w-full" @handleClose='openEditBox = false'>
        <div class="flex flex-col w-full overflow-x-hidden">
            <form @submit.prevent="updateWebshop" class="flex flex-col md:flex-row w-full">
                <div class="flex flex-col w-full md:w-1/2">
                    <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                        <span class="text-sm text-gray-600 w-1/2 mb-2">{{ $t('shopName') }} <span class="text-red-500">*</span></span>
                        <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                            <input type="text" v-model="webshopData.shop_name" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                        </div>
                    </div>
                    <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                        <span class="text-sm text-gray-600 w-1/2 mb-2">{{ $t('dropship') }} <span class="text-red-500">*</span></span>
                        <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                            <input type="text" v-model="webshopData.dropship" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                        </div>
                    </div>
                    <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                        <span class="text-sm text-gray-600 w-1/2 mb-2">{{ $t('country') }} <span class="text-red-500">*</span></span>
                        <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                            <select v-model="webshopData.country_code" class="w-full bg-white rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                                <option :value="item.key" v-for="(item, i) in countries" :key="i">{{ item.name }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                        <span class="text-sm text-gray-600 w-1/2 mb-2">{{ $t('shopPhone') }} <span class="text-red-500">*</span></span>
                        <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                            <input type="text" v-model="webshopData.contact_phone" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                        </div>
                    </div>
                    <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                        <span class="text-sm text-gray-600 w-1/2 mb-2">{{ $t('shopEmail') }} <span class="text-red-500">*</span></span>
                        <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                            <input type="email" v-model="webshopData.shop_owner_email" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                        </div>
                    </div>
                    <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                        <span class="text-sm text-gray-600 w-1/2 mb-2">{{ $t('shopURL') }} <span class="text-red-500">*</span></span>
                        <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                            <input type="text" v-model="webshopData.shop_url" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                        </div>
                    </div>
                    <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                        <span class="text-sm text-gray-600 w-1/2 mb-2">{{ $t('apiStore') }} <span class="text-red-500">*</span></span>
                        <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                            <input type="text" v-model="webshopData.apiStoreName" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                        </div>
                    </div>
                    <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                        <span class="text-sm text-gray-600 w-1/2 mb-2">{{ $t('clientSecret') }} <span class="text-red-500">*</span></span>
                        <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                            <input type="text" v-model="webshopData.secret" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                        </div>
                    </div>
                    <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                        <span class="text-sm text-gray-600 w-1/2 mb-2">{{ $t('clientID') }} <span class="text-red-500">*</span></span>
                        <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                            <input type="text" v-model="webshopData.clientId" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                        </div>
                    </div>
                    <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                        <span class="text-sm text-gray-600 w-1/2 mb-2">{{ $t('sendEmailTrackingAlert') }} <span class="text-red-500">*</span></span>
                        <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                            <input type="checkbox" class="checkbox" v-model="webshopData.email_tracking_alerts_to_buyer">
                        </div>
                    </div>
                    <!-- <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                        <span class="text-sm text-gray-600 w-1/2 mb-2">Logo<span class="text-red-500">*</span></span>
                        <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                            <input type="text" v-model="webshopData.logo" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                        </div>
                    </div> -->
                </div>
                <div class="flex flex-col w-full md:w-1/2">
                    <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                        <span class="text-sm text-gray-600 w-1/3 mb-2">{{ $t('emailServer') }} <span class="text-red-500">*</span></span>
                        <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                            <input type="text" v-model="webshopData.email_server" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                        </div>
                    </div>
                    <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                        <span class="text-sm text-gray-600 w-1/3 mb-2">{{ $t('emailSender') }} <span class="text-red-500">*</span></span>
                        <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                            <input type="text" v-model="webshopData.sender" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                        </div>
                    </div>
                    <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                        <span class="text-sm text-gray-600 w-1/3 mb-2">{{ $t('emailPassword') }} <span class="text-red-500">*</span></span>
                        <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                            <input type="password" v-model="webshopData.password" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                        </div>
                    </div>
                    <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                        <span class="text-sm text-gray-600 w-1/3 mb-2">{{ $t('emailPort') }} <span class="text-red-500">*</span></span>
                        <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                            <input type="text" v-model="webshopData.incoming_port" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                        </div>
                    </div>
                    <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                        <span class="text-sm text-gray-600 w-1/3 mb-2">{{ $t('emailCopy') }} <span class="text-red-500">*</span></span>
                        <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                            <input type="text" v-model="webshopData.cc_email_receiver" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                        </div>
                    </div>
                    <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                        <span class="text-sm text-gray-600 w-1/3 mb-2">{{ $t('emailSubject') }} <span class="text-red-500">*</span></span>
                        <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                            <input type="text" v-model="webshopData.email_subject" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                        </div>
                    </div>
                    <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                        <span class="text-sm text-gray-600 w-1/3 mb-2"></span>
                        <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                            <div class="flex mb-2">
                                <button type="submit" :disabled="processing" class="bg-theme-blue text-white px-4 py-1 rounded-md shadow-md focus:outline-none cursor-pointer">
                                    <span v-if="processing">{{ $t('updating') }}</span>
                                    <span v-else>{{ $t('update') }}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                        <span class="text-sm text-gray-600 w-1/4 mb-2">Note <span class="text-red-500">*</span></span>
                        <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                            <textarea cols="30" v-model="webshopData.note"  rows="5" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required></textarea>
                        </div>
                    </div> -->
                </div>
            </form>
        </div>
    </ModalBox>

    <ModalBox v-if="openDeletedModal" :title="$t('deleteSubscription')" @handleClose='openDeletedModal = false' :backdropOff='false'>
        <div class="flex flex-col w-full overflow-x-hidden">
            <h1 class="text-gray-500">{{ $t('deleteSubscription') }}?</h1>
            <div class="flex justify-end mt-2">
                <button @click="openDeletedModal = false" class="flex justify-center rounded whitespace-nowrap align-items-center  px-10 py-2 text-gray-100 text-gray-400 text-xs cursor-pointer border">{{ $t('cancel') }}</button>
                <button @click="proceedDeletion" :disabled='deleting' type="submit" class="flex justify-center rounded whitespace-nowrap align-items-center bg-red-500 px-10 py-2 text-white text-xs cursor-pointer border">
                    <span class="flex" v-if="deleting">
                        <svg class="animate-spin mr-2 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        {{ $t('deleting') }}
                    </span>
                    <span v-else>{{ $t('yesDelete') }}</span>
                </button>
            </div>
        </div>
    </ModalBox>
</template>

<script>
import { mapGetters } from 'vuex'
import ModalBox from '@/components/Modal'
import { getCountries } from './helper/countries'
import Bus from '../../bus'

export default {
    name : 'webshop',
    components : { ModalBox },
    data () {
        return {
            loading : false,
            processing : false,
            loadingShopify : false,
            openShopifyModal : false,
            openDeletedModal : false,
            deleting : false,
            shopName : '',
            globalShops : [],
            webshopData : {},
            openEditBox : false,
            countries : getCountries(),
            selectedData : {}
        }
    },
    computed : {
        ...mapGetters({
            USER_REFRESH : 'auth/USER_REFRESH',
            GET_WEBSHOPS : 'jitcontrol/GET_WEBSHOPS',
            GET_USER_INFORMATION : 'jitcontrol/GET_USER_INFORMATION',
            GET_CHOOSEN_COMPANY : 'jitcontrol/GET_CHOOSEN_COMPANY'
        })
    },
    mounted () {
        setTimeout(() => {
            this.getUser()
        }, 500);
    },
    methods : {
        getGlobalShop () {
            const payload = {
                URL : this.GET_CHOOSEN_COMPANY + `/shops`,
            }
            this.$store.dispatch('jitcontrol/customGetRequest', payload)
            .then(res => { 
                this.globalShops = res.data.results
            })
            .catch(err => {
                if (err.response.status === 401) {
                    this.$store.dispatch('auth/refreshToken', { refresh : this.USER_REFRESH })
                    .then(_ =>{
                        this.getGlobalShop()
                    })
                    .catch(_ => {
                        this.$store.commit('auth/CLEAR_AUTH_USER', null)
                        Bus.emit('sign-out')
                    })
                }
            })
        },
        initializingShopify () {
            if (this.shopName === '') {
                this.$services.helpers.notification(this.$t('fieldRequired'), 'error', this)
            } else {
                this.loadingShopify = true
                const shop = this.globalShops.filter(el => el.name === 'shopify');
                if (shop.length > 0) {
                    const payload = {
                        URL : this.GET_CHOOSEN_COMPANY + `/subscriptions`,
                        suppliers : [],
                        shop : shop[0].uuid
                    }
                    this.$store.dispatch('jitcontrol/customPostRequest', payload)
                    .then(res => {
                        const extra = {
                            URL : this.GET_CHOOSEN_COMPANY + `/subscriptions/${res.data.uuid}/shop_credentials`,
                            data : {
                                store : this.shopName,
                                token : ''
                            }
                        }
                        this.$store.dispatch('jitcontrol/customPostRequest', extra)
                        .then(response => {
                            this.loadingShopify = false
                            this.$services.helpers.notification(this.$t('success'), 'success', this)
                            // Redirect user to shopify and install app
                            setTimeout(() => {
                                window.open(`https://vast-ridge-21698.herokuapp.com/auth?shop=${this.shopName.toLowerCase()}.myshopify.com`)
                            }, 1000);
                        })
                        .catch(err => {
                            this.loadingShopify = false
                            if (err.response.status === 401) {
                                this.$store.dispatch('auth/refreshToken', { refresh : this.USER_REFRESH })
                                .then(_ =>{
                                    this.initializingShopify()
                                })
                                .catch(_ => {
                                    this.$store.commit('auth/CLEAR_AUTH_USER', null)
                                    Bus.emit('sign-out')
                                })
                            } else {
                                this.$services.helpers.notification(err.response.data, 'error', this)
                            }
                        })
                        
                    })
                    .catch(err => {
                        this.loadingShopify = false
                        if (err.response.status === 401) {
                            this.$store.dispatch('auth/refreshToken', { refresh : this.USER_REFRESH })
                            .then(_ =>{
                                this.initializingShopify()
                            })
                            .catch(_ => {
                                this.$store.commit('auth/CLEAR_AUTH_USER', null)
                                Bus.emit('sign-out')
                            })
                        } else {
                            this.$services.helpers.notification(err.response.data, 'error', this)
                        }
                    })
                }
            }
        },
        getUser () {
            if (this.GET_CHOOSEN_COMPANY) {
                this.getWebshop()
            }
        },
        getWebshop () {
            this.loading = true
            if (this.GET_CHOOSEN_COMPANY) {
                const payload = {
                    company : this.GET_CHOOSEN_COMPANY
                }
                this.$store.dispatch('jitcontrol/getWebshop', payload)
                .then(_ => {
                    this.loading = false
                })
                .catch(err => {
                    this.loading = false
                    if (err.response.status === 401) {
                        this.$store.dispatch('auth/refreshToken', { refresh : this.USER_REFRESH })
                        .then(_ =>{
                            this.getWebshop()
                        })
                        .catch(_ => {
                            this.$store.commit('auth/CLEAR_AUTH_USER', null)
                            Bus.emit('sign-out')
                        })
                    }
                })
            }
        },
        updateWebshop () {
            this.processing = true
            const company = this.GET_USER_INFORMATION[0].company ? this.GET_USER_INFORMATION[0].company.uuid : this.GET_USER_INFORMATION[0].user.company.uuid
            this.webshopData.URL = company + `/${this.$services.endpoints.SUBSCRIPTION_ENDPOINTS}`
            this.$store.dispatch('jitcontrol/customPatchRequest', this.webshopData)
            .then(res => {
                this.processing = false
                this.$services.helpers.notification(this.$t('updatedWebshop'), 'success', this)
            })
            .catch(err => {
                this.processing = false
                if (err.response.status === 401) {
                    this.$store.dispatch('auth/refreshToken', { refresh : this.USER_REFRESH })
                    .then(_ =>{
                        this.updateWebshop()
                    })
                    .catch(_ => {
                        this.$store.commit('auth/CLEAR_AUTH_USER', null)
                        Bus.emit('sign-out')
                    })
                }
                if (err.response.status === 400) {
                    this.$services.helpers.notification(err.response.data, 'error', this)
                }
            })
        },
        proceedDeletion () {
            this.deleting = true
            const payload = {
                URL : this.GET_CHOOSEN_COMPANY + `/subscriptions/${this.selectedData.uuid}`,
            }
            this.$store.dispatch('jitcontrol/customDeleteRequest', payload)
            .then(res => {
                this.deleting = false
                this.getWebshop()
                this.$services.helpers.notification(this.$t('success'), 'success', this)
                this.openDeletedModal = false
            })
            .catch(err => {
                this.deleting = false
                if (err.response.status === 401) {
                    this.$store.dispatch('auth/refreshToken', { refresh : this.USER_REFRESH })
                    .then(_ =>{
                        this.proceedDeletion()
                    })
                    .catch(_ => {
                        this.$store.commit('auth/CLEAR_AUTH_USER', null)
                        Bus.emit('sign-out')
                    })
                }
                if (err.response.status === 400) {
                    this.$services.helpers.notification(err.response.data, 'error', this)
                }
            })
        },
        openDeleteSubscription (data) {
            this.selectedData = data
            this.openDeletedModal = true
        },
        openModalEdit (data) {
            this.webshopData = data
            this.openEditBox = true
        }
    }
}
</script>

<style lang="scss" scoped>
.table td{
    padding: 1rem;
    // vertical-align: top;
    // border-top: 1px solid #dee2e6;
}
</style>